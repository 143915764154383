<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-2">Relatório Analítico</h1>
    <form class="mb-2" @submit.prevent="gerarRelatorio">
      <div class="grid grid-cols-12 gap-3">
        <div class="col-span-12 md:col-span-2">
          <label for="de" class="mt-2 block text-sm font-medium">De</label>
          <input v-model="form.dataDe" type="date" name="data" id="data" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
        </div>
        <div class="col-span-12 md:col-span-2">
          <label for="de" class="mt-2 block text-sm font-medium">Até</label>
          <input v-model="form.dataAte" type="date" name="data" id="data" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2" v-if="$store.state.user.admin">
          <label for="dataDisparo" class="mt-2 block text-sm font-medium text-gray-700"> Centro de custo</label>
          <v-select v-model="form.centrocusto" :options="centroscusto" label="nome" :reduce="(c) => ({ nome: c.nome, _id: c._id })" name="centrocusto" id="centrocusto" class="mt-1" />
        </div>
        <div class="col-span-12 md:col-span-2">
          <label for="de" class="mt-2 block text-sm font-medium">Email</label>
          <input v-model="form.email" type="email" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
        </div>
        <div class="col-span-12 md:col-span-3 xl:col-span-2">
          <button
            type="submit"
            class="md:mt-9 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            <span class="inline-block mr-2">Gerar relatório</span>
          </button>
        </div>
      </div>
    </form>
    <div class="col-span-12 grid grid-cols-12 mt-20">
      <h2 class="text-center text-2xl col-span-12 text-gray-500 font-semibold my-2" v-html="formatTitulo">{{ tituloRelatorio }}</h2>
      <span class="text-center col-span-12 text-red-500 font-semibold my-2">{{ mensagemErro }}</span>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../../components/Breadcrumbs.vue";
import moment from "moment";

export default {
  components: {
    Breadcrumb
  },

  data() {
    return {
      breadcrumb: [{ url: "/relatorios/relatorio-analitico", value: "Relatório analítico" }],
      centroscusto: [],
      mensagemErro: "",
      tituloRelatorio: "Insira seu email e uma data de filtro para gerar o relatório",
      palavrasChave: ["email", "data de filtro", "gerado", "enviado", "faltou"],
      form: {
        dataDe: moment().startOf("week").format("YYYY-MM-DD"),
        dataAte: moment().endOf("week").format("YYYY-MM-DD"),
        centrocusto: "",
        email: ""
      }
    };
  },

  computed: {
    formatTitulo() {
      let tituloFormatado = this.tituloRelatorio;
      this.palavrasChave.forEach((palavra) => {
        tituloFormatado = tituloFormatado.replace(new RegExp(palavra, "gi"), `<span class="text-${palavra === "gerado" ? "green" : "gray"}-700 font-bold">${palavra}</span>`);
      });
      return tituloFormatado;
    }
  },

  methods: {
    async gerarRelatorio() {
      this.mensagemErro = "";
      const erros = [];

      if (!this.form.email) {
        erros.push("O campo de email é obrigatório!");
      }

      if (!this.form.dataDe) {
        erros.push("O campo de data de início é obrigatório!");
      }

      if (!this.form.dataAte) {
        erros.push("O campo de data de término é obrigatório!");
      }

      if (this.form.dataDe && this.form.dataAte) {
        const dataDe = moment(this.form.dataDe);
        const dataAte = moment(this.form.dataAte);

        const diferencaEmMeses = dataAte.diff(dataDe, "months");

        if (diferencaEmMeses > 1) {
          erros.push("A diferença entre as datas pode ser no máximo de 1 (um) mês!");
        }
      }

      if (erros.length > 0) {
        this.tituloRelatorio = "Ops, parece que faltou algo!";
        this.mensagemErro = erros.join("\n");
        return;
      }

      const response = await this.$http.post(`/v1/campanhasLZV2/gerar-analitico`, { ...this.form });

      if (!response.data.success) {
        this.tituloRelatorio = "Ops, um erro ocorreu!";
        this.mensagemErro = response.data.err;

        return this.$vToastify.error(response.data.err);
      }

      this.tituloRelatorio = response.data.msg;
      return this.$vToastify.success("Sucesso, o relatório será enviado para seu email dentro de alguns minutos!");
    }
  },

  async beforeMount() {
    const centroscustoReq = await this.$http.post(`/v1/centrocusto/list`, { all: true });
    this.centroscusto = centroscustoReq.data.data;

    if (this.$store.state.user.centrocusto) {
      this.centrocusto = this.$store.state.user.centrocusto;
    }
  }
};
</script>
